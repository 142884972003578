@import "../../../global";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');


.gameHeader {
  padding: 0.2rem 3rem 0.6rem 3rem;
  background: $bg-alt;
  position: sticky;
  z-index: 5;
  top:0;
  font-family: $font-primary;
  .gName {
    font-weight: 600;
  }
  ul.nav-main a {
    font-family: Montserrat;
    font-weight: bold;
    display: block;
    // padding-left: 2em;
    // padding-right: 2em;
    width: 7.5rem;
    height: 2.8rem;
    text-align: center;
    background: rgb(206,17,103);
    // background: linear-gradient(120deg, rgba(206,17,103,1) 61%, rgba(245,98,33,1) 100%);
    border-radius: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 1rem;
    font-size: 1.5em;
    color: white;
    
    &:hover {
      cursor: pointer;
    }
  }

  
  img {
    max-width: 10em;
    padding: 0.5em;
  }
  img.play-logo {
    padding: 0;
  }
  .excel-play-logo {
    // background: $primary-alt;
    padding: 0 0.7em 0 0.7em;
    img {
      margin-top: 1rem;
    }
  }
  h1 {
    color: $primary;
    padding: 0.3em 0.2em 0 0.2em;
  }
  .sideMenu {
    position: absolute;
    visibility: hidden;
    z-index: +2;
    width: 100%;
    height: 100%;
    transition: visibility 0.2s ease-out;
    
  }
  .menu-icon {
    visibility: hidden;
    color: #fff;
    position: absolute;
    z-index: +2;
    margin-top: 15px;
    right: 10px;
    i {
      cursor: pointer;
      padding: 4px 6px;
      border-radius: 4px;
      
    }
  }
  .boxx{
    
  }
  @media screen and (max-width: $media-width) {
    .clicked{
      // background-color: rgba(36, 135, 166, 0.37);
      border-top: #fff 2px solid;
      border-bottom: #fff 2px solid;
    }
    // border-bottom: 0.4em solid $primary-alt;
    ul.nav-main a {
      padding-left: 2em;
      padding-right: 2em;
      background: linear-gradient(180deg, #9252D1 0%, #9B42AE 100%);
      font-size: 1.5em;
      color: white;
     border-radius: 5px;
    }
    img {
      max-width: 4em;
      max-height: 3em;
    }
    img.play-logo {
      padding: 0;
    }
    .excel-play-logo {
      // background: $primary-alt;
      padding: 0 0.7em 0 0.7em;
      z-index: 0;
    }
    .game-logo {
      margin: 0.5em 0.5em 0 0.3em;
    }
    h1 {
      color: $primary-alt;
      padding: 0.7em 0.2em 0.3em 0.2em;
      font-size: 1.5em;
    }

    .sideMenu {
      visibility: visible;
      //transition: visibility .5s ease-out;
    }

    .menu-icon {
      visibility: visible;
    }
  }
}
@media screen and (max-width: 500px){
  .gameHeader{
    padding: 1rem;
  }
}