@import "../../../global";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

.questionWrapper {
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  white-space: pre-line;
  font-family: Montserrat;
  // padding-top: 22.5rem;


  input {
    z-index: 0;
  }
  .btn-hint {
    margin: 3em;
  }
  .sourceHint {
    font-size: 16px;
    padding: 1rem 0 0 0;
  }

  .sourceHint.noImageQn {
    font-size: 20px;
    padding: 2rem 0 0 0;
  }

  .img {
    width: 260px;
    padding: 1em;
  }

  .bottomAnswer{
    position: absolute;
    top: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .answerWrapper {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    .answer {
      padding: 8px 16px;
      border: #fff 1px solid;
      border-radius: 30px;
      background-color: radial-gradient(
        49.39% 49.39% at 50% 65.04%,
        rgba(54, 3, 132, 0.4) 0%,
        #262626 100%
      );
      color: white;
      background-color: transparent;
      outline: none;
    }
    .submit__btn {
      background: rgb(206,17,103);
      background: linear-gradient(90deg, rgba(206,17,103,1) 61%, rgba(245,98,33,1) 100%);
      font-family: Montserrat;
      border-radius: 30px;
      border:none;
      color: white;
      padding: 8px 16px;
    }
    .submit__btn:active {
      box-shadow: none;
      transform: translate(3px,3px);
    }
   .btn {
      background-color: transparent;
      background-image: linear-gradient(90deg, #1D7EA1 0%, #4CBABB 100%);;
    }
    @media screen and (max-width: $media-width) {
      .answer{
        width: 60%;
      }
      
  }
  }


}
#hint_btn{
  color: #fff;
  font-family: Montserrat;
}

@media screen and (max-width:1000px) {

  .questionWrapper{
    .img{
      padding-top: 1em;
      width: 300px;
    }
  }
  
  .answerWrapper{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}