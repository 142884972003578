.doodle-wrapper {
    box-sizing: border-box;
    width: 100%;
    height: 100dvh;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.doodle-wrapper iframe {
    width: 100%;
    height: 100dvh;
    border: none;
    overflow: hidden;
    margin: 0;
    padding: 0;

}

