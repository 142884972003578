@import "./global";



html {
  overflow: initial !important;
}
.main-background {
  height: 100vh;
  width: 100vw;
  background-color: #06223A;
  color: white;
  overflow-x: hidden;
  // z-index: 9999999
}

.spinner-border {
  position: fixed;
  top: 50%;
  left: 50%;
}

input[type="checkbox"] {
  width: 16px; /*Desired width*/
  height: 16px; /*Desired height*/
}
