@import '../../../../../global';

.buy {
  margin: 1em;
  button:hover {
    opacity: 0.9;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .button {
    z-index: 2;
  }
  .increment-button {
    border: 0.2em white solid;
    border-radius: 50%;
    color: black;
    background: white;
    margin-left: -1.75em;
    margin-right: -1.75em;
    width: 3.6em;
    height: 3.6em;
  }
  #quantity-input {
    width: 100%;
    padding: 8px 10px;
    border: none;
    border-radius: 4px;
    background-color: #252525;
    color: white;
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }
  .quantity-wrapper {
    font-size: 2em;
  }

  .pending {
    .small {
      font-size: 0.6em;
    }
    .true {
      opacity: 0.3;
    }
  }

  .details table {
    font-size: 0.7em;
    th {
      color: $secondary;
    }
  }
  @media screen and (max-width: $media-width) {
    input {
      font-size: 2em;
    }

    .increment-button {
      border: 0.2em white solid;
      border-radius: 50%;
      color: black;
      background: white;
      margin-left: -1.75em;
      margin-right: -1.75em;
      width: 6.5em;
      height: 6.5em;
    }
  }
}
