@import '../../../../global';

.sideNav {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: -25%;
  left: -5%;
  .menu {
    position: absolute;
    width: 60%;
    height: 100%;
    background: $bg-alt;
    margin-left: -100%;
    z-index: +100;
    transition: margin-left 0.5s ease-out;
    padding: 4em 0 4em 0;
    .nav-item {
      margin: 0;
      padding: 0 0 1em 0;
      list-style: none;
    }
    .nav-item:hover {
      background: $bg-alt;
    }
    
    a {
      display: block;
      padding: 0 0 0 2em;
      font-size: 1.7rem;
      font-weight: 600;
      color: #fff;
    }
  }
  .menu.true {
    margin-left: 0;
    transition: margin-left 0.5s ease-out;
  }
  .backDrop {
    position: absolute;
    width: 100vw;
    height: 100%;
    background: black;
    opacity: 0.5;
    z-index: +4;
  }
  @media screen and (max-width: $media-width) {
    .backDrop {
      width: 104vw;
    }
  }
}
.border-box{
 height: 2.5rem;
 width: 6rem;
 position: absolute;
 box-sizing: border-box;
 border-radius: 4.42708px;
 transform: matrix(1, -0.09, 0.09, 1, 0, 0);
 z-index: -1;
 }
 @media screen and (max-width: 1000px){
   .border-box{
     display: none;
   }
 } 

.sideMenu.false {
  visibility: hidden;
}
