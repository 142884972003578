@import "../../../global";

.rules {
  width: 100%;
  font-size: 1.1rem;
  padding: 40px 20px;
  height: 100dvh;
  overflow-y: auto;

  padding-bottom: 100px;
  li,
  h2 {
    letter-spacing: 0.25px;
    color: #dfdfee;
  }
}
