@import "../../../global.scss";

@media screen and (min-width: $media-width) {
  .kryptos-page {
    position: relative;
    min-height: calc(100vh - 5rem);
    height: 100%;
    width: 100%;
    margin: 0;
    padding-bottom: 2rem;
    // width: 100vw;
  }
  .winner-screen {
    padding-top: 3rem;
    .caption {
      font-size: 1.25rem;
    }
    .title {
      font-size: 2rem;
      color: #17769F;

    }
  }

  .abs-rank {
    height: 100%;
    position: absolute;
    position: -webkit-sticky;
    bottom: 0; /* required */
    right: 0;
    // overflow-y: auto;
    // overflow-x: hidden;
    // right: 0;
  }
}

@media screen and (max-width: $media-width) {
  .rank {
    display: none;
  }
  .winner-screen {
    padding-top: 3rem;
    .caption {
      font-size: 1rem;
    }
    .title {
      font-size: 1.25rem;
      color: #17769F;
    }
  }
}

.winner-screen {
  color: #17769F;
  .caption {
    color: aliceblue;
  }
}
