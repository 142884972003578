@import "../../global";

.main{
  background-color: #170109e0;
  background-image: url('../../assets/particles.png');
  background-size:cover;
  background-repeat: no-repeat;

  .top{
    position: absolute;
    top: 0;
    left: 0;
  }
  

  .play{
    position: absolute;
    top: 50%;
    left: 2vw;
    width: 15%;
    height: auto;
  }

  .bottom{
    position: absolute;
    bottom:0;
    left:0;
  }
}

.carouselCard{
  width: 80vw;
  height: 60vh;
  border-radius: 20px;
  cursor: pointer;
  background: rgb(209,14,85);
  background: url("../../assets/desktopMask.png"), -moz-linear-gradient(25deg, rgba(209,14,85,1) 55%, rgba(245,98,33,1) 100%);
  background: url("../../assets/desktopMask.png"), -webkit-linear-gradient(25deg, rgba(209,14,85,1) 55%, rgba(245,98,33,1) 100%);
  background: url("../../assets/desktopMask.png"), linear-gradient(25deg, rgba(209,14,85,1) 55%, rgba(245,98,33,1) 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .rankBox{
    position: absolute;
    top:20px;
    left: 20px;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background-color: #fff;
    color: #1A1A1A;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
    line-height: 1.2rem;
    .title{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p{
        margin: 0;
        font-size: 10px;
        font-weight: bold;
        line-height: 1rem;
      }
    }
    
  }
  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    border-radius: 900px;
    background-color: #882020;
    aspect-ratio: 1/1;
  }

  .gameName{
    margin: 20px 0;
    font-size: 32px;
    color: #1A1A1A;
    font-weight: bold;
  }

  .playButton{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #882020;

    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: bold;
  }
}

.flexbox {
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 6vw;
  align-items: center;
  justify-content: center;
  padding-top: 15vh;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  // background: #A023BB;
}

::-webkit-scrollbar-thumb {
  background:
    #771CD1;
  height: 20px;
  width: 10px;
  border-radius: 20px;
}

.cell {
  cursor: pointer;
  // padding: 30px;
  font-weight: 800;
  font-size: 1.7em;
  padding: 1rem 0;
  // background-color: yellow;
  color: $primary-alt;
  font-family: $font-homePage;
  margin-left: 6vw;
  height: auto;

  @media screen and (max-width: $media-width) {
    // padding: 0.5rem 1rem;
    font-size: 1em;
  }
}

@media screen and (min-width:1000px) {
  .row1 {
    height: 100vh;
  }
}

@media screen and (max-width:1000px) {
  .top{
    display: none;
  }
  .bottom{
    display: none;
  }

  .play{
    display: none;
  }
  .container {
    padding-top: 10rem;
    height: 100vh;
    overflow: hidden;
  }
  .flexbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    margin-left: 0;
    padding-top: 1vh;
  }
  .left{
    width: 60px;
  }
  .right{
    width: 60px;
    transform: rotate(180deg);
  }
}