@import "../../../global.scss";

.games {
  color: #1A1A1A;
  padding: 1.5rem;
  border-radius: 24px;
  backdrop-filter: blur(8px); // This be the blur
  max-width: 28rem;
  margin-left: auto;
  margin-right: auto;
  a {
    text-decoration: none;
    color: inherit;
    font-size: inherit;
  }

  .title {
    z-index: 1;
    color: #1A1A1A;
    font-weight: 800;
    font-size: 2rem;
    letter-spacing: 1.5px;
    margin-top: .5rem;
    margin-left: 2rem;

  }
  .gameCell {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .gameCell > div {
      margin: 0 2em;
      
    }

    .Rankdetail {
      display: flex;
     
    }

    .rankDiv {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      color: $play-now;
      margin-left: 1rem;
    }

    .pic-box {
      position: relative;
      background-color: #882020;
      aspect-ratio: 1/1;
      border-radius: 100%;
      // -webkit-box-shadow: 6px 6px 26px 8px rgba(0, 0, 0, 0.22);
      // -moz-box-shadow: 6px 6px 26px 8px rgba(0, 0, 0, 0.22);
      // box-shadow: 6px 6px 26px 8px rgba(0, 0, 0, 0.22);
    }
    
    .gamecell__box2{
      display: flex;
      justify-content: space-between;
    }
    .rank__container{
      display: flex;
      flex-direction: row;
      margin-top: 0.5rem;
      font-family: $font-primary;
      font-weight: 400;
    }

    .rank {
      
      display: flex;
      
     
      align-items: center;
    }

    .PlayNow {
      font-size: 0.8em;
      color: $play-now;
    }

    // Event logo
    .pic {
      color: white;
      width: 60%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .play-button {
    font-weight: 600;
    position: absolute;
    color: #fafaf1;
    background: #e17f00;
    bottom: 1.2rem;
    padding: 0.4em 0.8rem;
    border-radius: 64px;
    font-family: $font-primary;
  }
  .play-button2{
    font-weight: 400;
    position: absolute;
    color: #fafaf1;
    font-style: italic;
    bottom: 1.2rem;
    font-family: $font-primary;

    

  }
}

.disabled-game {
  color: rgba(255, 255, 255, 0.4) !important;

  .title{
    color: rgba(255, 255, 255, 0.4) !important;
  }
  .pic-box {
    opacity: 0.4;
  }
  
}

@media only screen and (min-width: 1000px) {
  .games {
    height: 13rem;
    font-size: 1rem;
    padding: 2rem;
    width: 23rem;

    .rank p {
      color: #1A1A1A;
      font-size: 1.2rem;
    }

    .title {
      color: #1A1A1A;
      font-size: 1.7rem;
      font-weight: 300;
      // text-align: right;
     
      
    }
    .pic-box {
      height: 7rem;
      width: 7rem;
    }

    .RankValue {
      transform: translateY(0.5rem);
      font-size: 1.2rem;
      color: #1A1A1A;
    }

    .play-button,.play-button2 {
      right: 5rem;
      transform: translateX(50%);
    }
  }
}

@media only screen and (max-width: 1000px) {
  .games {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    height: 10rem;
    width: 18rem;
    

    .rank p {
      color: rgba(255, 255, 255, 0.8);
     
      font-size: 0.8rem;
      margin-left: 0.5rem;
    }

    .title {
      margin-left: .6rem;
      font-size: 1.1rem;
      margin-bottom: 4px;
      font-weight: 100;
    }

    .pic-box {
      height: 5rem;
      width: 5rem;
    }

    .RankValue {
      margin-left: -5px;
      margin-bottom: 20px;
      font-size: 1rem;
      color: white;
      transform: translateY(0.4rem);
    }
    .play-button,.play-button2 {
      right: 4.125rem;
      font-size: 0.8rem;
      transform: translateX(50%);
    }
  }
}

$color-1: rgba(204, 72, 233, 0.75) rgba(204, 72, 233, 0.1625) ;
$color-2: rgba(204, 72, 233, 0.3) rgba(204, 72, 233, 0.1) ;
$number: 1 2 3 4 5;


@each $a, $b, $i in zip($color-1, $color-2, $number) {
  .bg-#{$i}::before {
    content: "";
    position: absolute;
    padding: 2rem;
    border-radius: 24px;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    filter: blur(1px);
    z-index: 0;
    background: rgb(209,14,85);
    background: url("../../../assets/desktopMask.png"), -moz-linear-gradient(25deg, rgba(209,14,85,1) 55%, rgba(245,98,33,1) 100%);
    background: url("../../../assets/desktopMask.png"), -webkit-linear-gradient(25deg, rgba(209,14,85,1) 55%, rgba(245,98,33,1) 100%);
    background: url("../../../assets/desktopMask.png"), linear-gradient(25deg, rgba(209,14,85,1) 55%, rgba(245,98,33,1) 100%);
    background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  }
}