@import '../../../../../global';
.stock {
  .table {
    font-size: 2em;
  }
  th {
    width: 50%;
    text-align: right;
    color: $secondary;
  }
  @media screen and (max-width: $media-width) {
    font-size: 0.5em;
    .table {
      font-size: 5em;
    }
  }
}
