@import "../../../global";

.hide-ranklist {
  // margin-left: calc(100vw - 65vw) !important;
  transform: translateX(100%);
  transition: transfrom 0.5s ease-in-out;
}

.hidable-ranklist {
  // margin-left: 4vw;
  width: 30vw;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 4px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: dimgrey;
}

::-moz-scrollbar {
  background-color: transparent;
  width: 4px;
}
::-moz-scrollbar-track {
  background-color: transparent;
}
::-moz-scrollbar-thumb {
  background-color: dimgrey;
}



.ranklist {
  overflow-y: auto;
  position: absolute;
  top: -3.7rem;
  height: 90vh;
  padding: 0%;
  // margin-left: 100%;
  transition: transform 1s ease-in;

  .heading {
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 1.5rem;
    color: $primary;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: dimgrey;
  }
  .table {
    border-collapse: collapse;
    border: none !important;
    font-size: 1.5rem;
    color: $primary-alt;
  }

  td,
  th {
    border: none !important;
    background-color: #ce11677d;
  }

  tr {
    background-color: rgba(36, 135, 166, 0.22);
    &:nth-child(even) {
      background-color: rgba(36, 135, 166, 0.22);
    }
  }

  table .propic {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;

    display: block;
  }

  td {
    font-size: 1.4rem;
    word-wrap: break-word;
    color: $primary;
  }

  th {
    font-weight: 600;
    font-size: 1.2rem;
    word-wrap: break-word;
    color: #fff;
  }

  .rank-level {
    // text-align: center;
  }

  @media screen and (max-width: $media-width) {
    .table,
    td,
    th {
      font-size: 1rem;
    }
    #gameover-header {
      background-image: linear-gradient(90deg, #1D7EA1 0%, #4CBABB 100%);;
      background-color: transparent;
    }
  }
}

.final {
  position: unset !important;
  border-radius: 0 50px 0 0;
}

@media screen and (max-width: $media-width) {
  .ranklist {
    border: none;
    position: relative;
    border-radius: 0;
  }
}

