@import './global';

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  font-family: $font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  font-size: 0.7em;
  @media screen and (max-width: $media-width) {
    font-size: 1em;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
