@import "../../../global";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');


.big-header-bg{
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  position: absolute;
  top: 0;
  height: 12vh;
  padding-right: 5%;
}

.excel-logo-container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: auto;
  margin-right: 5%;
  .logo{
    width: 80px;
    height: auto;
  }
  img{
    width: 140px;
  }
}

.excel-logo-container-go{
  display: none;
}

.logo-container{
  display: none;
}

.userDetails{
  height: auto;
  .btn{
    color: $primary-alt;
    font-weight: bold;
    font-size: 12;
  }
  .propic{
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 1000px){
  .big-header-bg{
    justify-content: space-evenly;
    padding: 0;
  }

  .excel-logo-container{
    margin: 0;
    width: 70px;
    img{
      width: 70px;
    }
  }
  .excel-logo-container-go{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: auto;
    margin: 0;
    .logo{
      width: 80px;
      height: auto;
    }
    img{
      width: 70px;
    }
  }

  

  .logo-container{
    display: inline;
    img{
      width: 120px;
    }
  }

  .userDetails{
    width: 70px;
    .propic{
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-right: 0;
    }
  }
}
